@tailwind components;

@layer components {
  .search-forms {
    border: 1px solid rgba(5, 5, 5, 0.1);
    @apply border-r-0 border-l-0;
  }
  .search-forms .ant-form-item {
    border: 1px solid rgba(5, 5, 5, 0.06);
    @apply h-[50px] m-0 rounded-none border-r-0 border-l-0;
  }
  .search-forms .ant-form-item .ant-form-item-row {
    @apply h-full border-r-0;
  }
  .search-forms .ant-form-item .ant-form-item-label {
    @apply bg-black bg-opacity-[.02] pl-7 w-[140px] h-full flex items-center;
  }
  .search-forms .ant-form-item .ant-form-item-label > label::after {
    @apply content-none;
  }
  .search-forms .ant-form-item .ant-form-item-label .ant-form-item-required:before {
    @apply absolute left-[-10px];
  }
  .search-forms .ant-form-item .ant-form-item-control {
    @apply h-full flex justify-center;
  }
  .search-forms .ant-form-item .ant-form-item-control .ant-form-item-control-input,
  .search-forms
    .ant-form-item
    .ant-form-item-control
    .ant-form-item-control-input
    .ant-form-item-control-input-content {
    @apply h-full;
  }
  .search-forms
    .ant-form-item
    .ant-form-item-control
    .ant-form-item-control-input
    .ant-form-item-control-input-content
    .ant-input-affix-wrapper,
  .search-forms
    .ant-form-item
    .ant-form-item-control
    .ant-form-item-control-input
    .ant-form-item-control-input-content
    .ant-picker,
  .search-forms
    .ant-form-item
    .ant-form-item-control
    .ant-form-item-control-input
    .ant-form-item-control-input-content
    .ant-input {
    @apply w-full border-0 rounded-none;
  }
  .search-forms
    .ant-form-item
    .ant-form-item-control
    .ant-form-item-control-input
    .ant-form-item-control-input-content
    .ant-input-affix-wrapper.ant-input-affix-wrapper-status-error,
  .search-forms
    .ant-form-item
    .ant-form-item-control
    .ant-form-item-control-input
    .ant-form-item-control-input-content
    .ant-picker.ant-picker-status-error {
    @apply border-[1px];
  }
  .search-forms
    .ant-form-item
    .ant-form-item-control
    .ant-form-item-control-input
    .ant-form-item-control-input-content
    > * {
    @apply h-full pl-4;
  }
  .search-forms .ant-form-item .ant-form-item-control .ant-form-item-control-input .ant-select {
    @apply p-0;
  }
  .search-forms
    .ant-form-item
    .ant-form-item-control
    .ant-form-item-control-input
    .ant-select
    .ant-select-selector {
    @apply w-full h-full items-center border-0 rounded-none pl-4;
  }
}
